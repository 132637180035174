import { useNavigate } from "react-router-dom";
import Signature from "../assets/images/signatureVincent.png";
import Layout from "./layout";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Cgu = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <HelmetProvider>
        <Helmet>
          <title>CGU </title>
          <meta
            name="description"
            content="page Conditions Générales d’utilisation"
          />
        </Helmet>
      </HelmetProvider>

      <div className="p-16 col-lg-6 mx-auto">
        <div className=" text-start">
          <div className=" ">
            <div className="">
              <p>
                <strong>1. OBJET</strong>
              </p>
              <p>
                ATCHOUM est une plateforme de mise en relation entre habitants
                d’un territoire abonné à son centre d’appel afin de partager des
                Trajets automobiles sur sa Plateforme de mise en relation
                ATCHOUM, composée d’un Site internet https://www.atchoum.eu/,
                d’une application mobile et d’un centre d’appels téléphonique
                pour des Conducteurs se rendant d’un point de départ à une
                destination donnée et des Passagers qui se trouvent sur ce
                Trajet et souhaitent aller d’un point de départ à un point
                d’arrivée se trouvant sur ce même Trajet . Cette Plateforme
                ATCHOUM est plutôt destinée aux courtes distances en territoire
                rural qu’ATCHOUM appelle courvoiturage© mais qui permet aussi de
                pratiquer le Covoiturage moyennes et longues distances. Des
                Trajets solidaires peuvent être également être organisés et
                effectués et dans ce cas le Conducteur va modifier son Trajet
                pour aller chercher le Passager où il se trouve et le déposer où
                il souhaite se rendre. De même des Trajets d’Utilité Sociales
                peuvent être organisés et effectués dans le cadre du Transport
                d’Utilité Sociale (TUS), tels que définis dans le Décret n°
                2019-850 du 20 août 2019 relatif aux services de transport
                d'utilité sociale, par la plateforme de mise en relation ATCHOUM
                occasionnant ainsi des Trajets d’Utilité Sociale. ATCHOUM inclus
                ces trois formes de mobilité. ATCHOUM agit en tant que
                facilitateur de la mise en relation et n'est partie à aucun
                contrat entre ses Membres dans ces trois modalités de trajet.
              </p>
              <p>
                Cette Plateforme ATCHOUM de mise en relation est conçue,
                développée et gérée par ATCHOUM SAS dont le siège social est
                situé 36 Route de Toulon 83136 MEOUNES LES MONTRIEUX,
                propriétaire de la marque ATCHOUM© et est opérée par
                l’ « Association ATCHOUM, Mobilité Villages » dans le cadre
                d’une convention de prestation de services. Dans le cadre du
                partage de Trajets (Covoiturage, Trajets Solidaires ou Trajets
                d’Utilité Sociale), les frais y afférents sont partagés entre le
                ou les Passagers et le Conducteur du véhicule effectuant le
                Trajet dans le cadre et le respect des textes en vigueur dont
                quelques éléments sont rappelés ci-dessous :
                <small>
                  (* source : ministère de la transition écologique et
                  solidaire)
                </small>
              </p>
              <p>Définition du covoiturage*</p>
              <p>
                La loi du 17 août 2015 relative à la transition énergétique pour
                une croissance verte (TECV) définit le Covoiturage (article L.
                3132-1 du code des Transports) : « l’utilisation en commun d’un
                véhicule terrestre à moteur par un Conducteur et un ou plusieurs
                Passagers, effectuée à titre non onéreux, excepté le partage des
                frais, dans le cadre d’un déplacement que le Conducteur effectue
                pour son propre compte ».
              </p>
              <p>Prix du covoiturage</p>
              <p>
                D’après l’administration fiscale, le coût du Trajet peut être
                évalué en utilisant le barème kilométrique (qui comprend
                notamment les frais de carburant, l’assurance et l’amortissement
                du véhicule) et en y ajoutant le prix des péages éventuels. Le
                Conducteur ne doit pas être en situation de bénéfice, et donc ne
                doit pas recevoir de la part de ses Passagers une somme
                supérieure au prix réel du Trajet fixé par le barème fiscal,
                soit environ 0,50 € du kilomètre selon les cylindrées + le
                péage. Sinon, il entre dans le champ du transport rémunéré de
                personnes, dont l’accès et l’exercice de la profession est
                réglementé, et est assujetti à la souscription d’une assurance
                professionnelle spécifique afin que ses Passagers soient
                couverts.
              </p>

              <p>Assurance Conducteur *</p>
              <p>
                Le Covoiturage et partage de trajets est une offre désintéressée
                de service de transport, dont l’éventuelle contrepartie
                financière est limitée au partage des frais (carburant, péage
                par exemple). Si un Membre de la plateforme ATCHOUM propose un
                Covoiturage ou effectue un Trajet solidaire ou un Transport
                d’Utilité Sociale en tant que Conducteur, il doit être couvert
                par une assurance responsabilité civile, soit celle comprise
                dans sa police d’assurance automobile, soit souscrite à cet
                effet si elle n’est pas comprise dans sa police d’assurance
                auto, ce que le Conducteur doit vérifier. Cette garantie couvre
                les dommages qui peuvent être occasionnés à des tiers lors d’un
                sinistre et le Passager du Covoiturage est aussi couvert par
                cette assurance obligatoire. Certaines situations peuvent
                cependant être exclues de la couverture offerte par cette
                garantie comme le prêt de volant (franchise). Nous préconisons
                aux Conducteurs de contacter leur assureur pour vérification de
                la prise en charge.
              </p>
              <p>Assurance Passager *</p>
              <p>
                En tant que Passager, celui-ci est couvert par la garantie «
                responsabilité civile », qui est incluse dans les contrats
                d’assurance automobile souscrits par les particuliers
                Conducteurs ou souscrite spécialement à l’effet de covoiturer ou
                partager des Trajets dans le cadre des obligations pour être en
                règle afin de conduire un véhicule dans les conditions du Trajet
                effectué. En cas d’accident, si le Passager est blessé, c’est
                l’assurance du Conducteur responsable de l’accident qui prendra
                en charge l’indemnisation de la totalité de ses dommages
                corporels, aucune franchise ne pouvant être laissée à sa charge.
              </p>
              <p>Exonération d’impôt des revenus tirés du covoiturage*</p>
              <p>
                Les conditions d’exonération d’impôt des revenus tirés du
                Covoiturage ont été précisées par une circulaire fiscale en date
                du 30 août 2016. Les revenus encaissés dans le cadre d’un
                Covoiturage sont exonérés d’impôt sur le revenu et n’ont pas à
                être déclarés dès lors qu’il y a participation du contribuable
                en tant que Conducteur. Mais, le revenu encaissé ne doit pas
                dépasser le montant total des frais liés au Trajet, déduction
                faite de la quote-part du Conducteur.
              </p>
              <p>
                Les présentes Conditions Générales d’Utilisation (CGU) ont pour
                objet d’encadrer l’accès et les modalités d’utilisation de la
                Plateforme ATCHOUM et chaque utilisateur inscrit sur cette
                plateforme est réputé les avoir lues et acceptées pour en être
                Membre. En les lisant chaque Membre est réputé comprendre et
                accepter qu’ATCHOUM n’est partie prenante à aucun accord,
                contrat ou relation contractuelle, de quelque nature que ce
                soit, conclu entre les Membres inscrits sur sa Plateforme
                ATCHOUM en tant que Passagers et/ou Conducteurs. Le fait de
                s’inscrire sur la Plateforme ATCHOUM emporte la reconnaissance
                par chaque Membre inscrit d’avoir pris connaissance des
                présentes CGU et de les accepter c’est-à-dire qu’elles
                s’imposent à lui de manière globale et pour tous toutes ses
                composantes.
              </p>

              <p>
                <strong>
                  2. DE QUOI PARLE-T-ON SUR LA PLATEFORME ATCHOUM ?
                </strong>
              </p>
              <p>
                Les termes ci-dessous surlignés en gras auront la signification
                décrite ci-après, dans les CGU:
              </p>
              <p>
                <b>CGU: </b>désigne les présentes Conditions Générales
                d’Utilisation
              </p>
              <p>
                <b>Collectivité : </b> désigne la Collectivité qui a souscrit
                l’abonnement au centre d’appels téléphoniques qui ouvre le droit
                pour les habitants de son territoire et dans le cadre de sa
                convention d’abonnement au centre d’appels téléphoniques avec
                ATCHOUM, de faire bénéficier ceux-ci des Fonctionnalités de son
                Site internet, de son application mobile et de son centre
                d’appels.
              </p>
              <p>
                « Conducteur » désigne le Membre proposant, sur la Plateforme
                ATCHOUM, de faire profiter un autre Membre (des autres Membre s)
                d’un ou plusieurs de ses Trajet s dans le cadre d’une
                participation aux frais.
              </p>
              <p>
                <b>Utilisateur: </b> Personne qui visite et explore le site
                internet ATCHOUM sans toutefois s’y inscrire.
              </p>
              <p>
                <b>Membre : </b> Désigne toute personne physique Utilisateur qui
                s’inscrit sur la plateforme Atchoum en fournissant tous les
                renseignements, documents demandés et qui accepte les présentes
                CGU.
              </p>
              <p>
                <b>Conducteur : </b> désigne le Membre proposant, sur la
                Plateforme ATCHOUM, de faire profiter un autre Membre (des
                autres Membre s) d’un ou plusieurs de ses Trajet s dans le cadre
                d’une Participation aux Frais.
              </p>
              <p>
                <b>Passager :</b> désigne le membre ayant accepté la proposition
                de partage de trajet d’un Conducteur dans le cadre sa
                participation aux frais ou le cas échéant, la personne pour le
                compte de laquelle un Membre a réservé une Place
                <br />
                <b>Confirmation de Trajet :</b> désigne le message sous forme de
                mail et /ou SMS et/ ou appel téléphonique envoyé aux Membres dès
                qu’ils ont décidé et accepté de covoiturer ensemble par
                l’intermédiaire de la plateforme ATCHOUM.
                <br />
                <b>Covoiturage : </b>conformément à la loi du 17 août 2015
                relative à la transition énergétique pour une croissance verte
                (TECV), le Covoiturage est (article L. 3132-1 du code des
                Transports) : « l’utilisation en commun d’un véhicule terrestre
                à moteur par un Conducteur et un ou plusieurs Passagers,
                effectuée à titre non onéreux, excepté le partage des frais,
                dans le cadre d’un déplacement que le Conducteur effectue pour
                son propre compte ».
                <br />
                <b>Fonctionnalités :</b> désigne les interactions entre Membres
                de la plateforme et de ceux-ci avec ATCHOUM
                <br />
                <b>Inscription : </b>désigne le fait de fournir les
                renseignements demandés avec prise de connaissance et
                acceptation pleine et entière des présentes conditions générales
                pour pouvoir utiliser la plateforme
                <br />
                <b>Organisme :</b> désigne l’Organisme qui a souscrit
                l’abonnement au centre d’appels téléphoniques qui ouvre le droit
                pour ses ressortissants et dans le cadre de sa convention
                d’abonnement au centre d’appels avec ATCHOUM SAS, de faire
                bénéficier ceux-ci des Fonctionnalités de son Site internet, de
                son application mobile et de son centre d’appels.
                <br />
                <b>Participation aux Frais :</b> désigne, pour un Trajet en
                Covoiturage donné, complet ou tronçon, la somme d’argent
                demandée par le Conducteur et acceptée par le Passager au titre
                de sa Participation aux Frais de Trajet.
                <br />
                <b>Place :</b> désigne la Place réservée par un Passager à bord
                du véhicule d’un Conducteur pour un Trajet donné.
                <br />
                <b>Plateforme : </b>désigne le Site internet, la base de
                données, les Fonctionnalités produites par les logiciels,
                algorithmes et intelligence artificielle et les contenus qui y
                sont publiés par les Membres et par ATCHOUM.
                <br />
                <b>Site : </b>désigne le Site internet accessible à l’adresse
                www.atchoum.eu
                <br />
                <b>Ticket Mobilité ATCHOUM :</b> moyen de paiement mis en Place
                pour les Membres qui ne peuvent ou ne veulent pas utiliser la
                carte bancaire ou le prélèvement automatique SEPA. Les tickets
                Mobilité ATCHOUM sont numérotés afin de ne pouvoir être utilisés
                qu’une seule fois.
                <br />
                <b>Trajet :</b> désigne le parcours effectué par un Conducteur
                ou un Passager pour se rendre d’un point de départ à un point
                d’arrivé fixé par l’un ou l’autre. « Trajet » désigne
                indistinctement un Trajet en Covoiturage ou un segment d’un
                Trajet, un Trajet solidaire ou un Trajet effectué dans le cadre
                d’un Transport d’Utilité Sociale (TUS) Un trajet en covoiturage
                désigne un Trajet faisant l’objet d’une annonce de Trajet
                publiée par un Conducteur sur la Plateforme ATCHOUM et pour
                lequel il accepte de transporter des Passagers en contrepartie
                de la Participation aux Frais, que ce soit un Trajet complet ou
                un tronçon de Trajet comme défini à « Trajet ». Trajet Solidaire
                désigne un Trajet pour lequel le Conducteur se déroute pour
                quérir et déposer un Passager, Trajet d’Utilité Sociale désigne
                un Trajet effectué dans le cadre d’un Transport d’Utilité
                Sociale (TUS). Un Trajet solidaire désigne des Trajets où le
                Conducteur adapte volontairement son itinéraire pour répondre à
                des besoins spécifiques d’entraide ou de soutien communautaire.
                Ces Trajets s’inscrivent dans une démarche non commerciale, dans
                l’esprit de solidarité et d’utilité locale. Par exemple, les
                Trajets solidaires peuvent inclure le transport de personnes
                âgées, de personnes en difficulté de mobilité ou de familles ne
                disposant pas de moyen de transport. Ces Trajets sont souvent
                encouragés par des dispositifs locaux ou associatifs, mais le
                Conducteur ne doit pas en tirer un profit financier. Enfin, un
                Trajets d’Utilité Sociale correspond à la définition du Décret
                n° 2019-850 du 20 août 2019 relatif au transport d’utilité
                sociale : « Des Trajets organisés pour répondre aux besoins de
                déplacement des personnes rencontrant des difficultés
                particulières d’accès à un transport public ou privé ».
                <br />
                <p>
                  <strong>
                    3. INSCRIPTION À LA PLATEFORME ATCHOUM ET CREATION DE SON
                    COMPTE
                  </strong>
                </p>
                <p>3.1 Conditions d’Inscription</p>
                <p>
                  L’accès à la Plateforme ATCHOUM est strictement réservé aux
                  personnes physiques âgées de 18 ans ou plus. Une Inscription
                  par une personne mineure est interdite, sauf autorisation
                  expresse écrite des parents ou représentant légal, à
                  transmettre à l’Association Atchoum, Mobilité villages. Les
                  utilisateurs déclarent et garantissent être majeurs. ATCHOUM
                  décline toute responsabilité en cas d’Inscription frauduleuse
                  par un mineur.
                </p>
                <p>3.2 Modalités d’Inscription</p>
                <p>
                  Accès aux Fonctionnalités : la consultation des Annonces de
                  Trajet est possible sans Inscription. Toutefois, la
                  publication ou la réservation de Trajets requiert une
                  Inscription préalable et donc d’être Membre.
                </p>
                <p>
                  Procédure d’Inscription : les utilisateurs peuvent s’inscrire
                  en remplissant le formulaire en ligne ou via le centre
                  d’appels, qui peut compléter le processus d’Inscription à leur
                  Place. Les CGU peuvent être envoyées par courrier postal pour
                  être signées par l’utilisateur.
                </p>
                <p>
                  Engagements des utilisateurs :<br />
                  ⦁ Fournir des informations personnelles exactes, les mettre à
                  jour en cas de modification et respecter la confidentialité de
                  leur mot de passe.
                  <br />
                  ⦁ En cas de perte ou d’utilisation frauduleuse du mot de
                  passe, notifier ATCHOUM sans délai.
                  <br />
                  ⦁ S’inscrire sous sa propre identité et ne créer qu’un seul
                  compte, sauf demande expresse d’ATCHOUM en cas de problème
                  technique.
                  <br />
                  ⦁ Vérification des Inscriptions
                  <br />
                  Vérifications possibles : ATCHOUM peut vérifier certaines
                  informations pour renforcer la transparence, prévenir les
                  fraudes et améliorer la confiance entre utilisateurs. Ces
                  vérifications peuvent inclure : une validation du numéro de
                  téléphone, de la pièce d’identité, de la carte grise, de
                  l’attestation d’assurance, du contrôle technique et de
                  l’adresse email.
                  <br />
                  Limitation des garanties : ATCHOUM ne garantit pas
                  systématiquement la vérification des informations fournies par
                  les utilisateurs et peut réaliser des contrôles ponctuels.
                  <br />
                  Engagement des utilisateurs : chaque utilisateur s’engage à
                  fournir des informations véridiques et actualisées.
                </p>
                <p><strong>4. UTILISATION DES FONCTIONNALITES DE LA PLATEFORME ATCHOUM</strong></p>
              
              <p><strong>4.1 Inscription et Création de Compte</strong></p>
              <p>Les utilisateurs peuvent s'inscrire sur la Plateforme en créant un compte personnel. Lors de l'Inscription, les Membres doivent fournir des informations exactes (nom, prénom, email, numéro de téléphone) et accepter les CGU. Un système de validation (email ou SMS) peut être utilisé pour garantir l'authenticité des profils.</p>
              
              <p><strong>Publication des Annonces de Trajet</strong></p>
              <p>Conditions de publication : en tant que Membre, et sous réserve de remplir les conditions ci-dessous, il est possible de créer et publier des Annonces de Trajet sur la Plateforme ATCHOUM en indiquant des informations suivantes :</p>
              <p>⦁ Lieu et heure de départ.</p>
              <p>⦁ Destination et arrêts intermédiaires possibles.</p>
              <p>⦁ Nombre de Places disponibles.</p>
              <p>⦁ Participation financière demandée (conforme au barème fiscal).</p>
              <p>⦁ Préférences particulières (non-fumeurs, musique, etc.).</p>
              
              <p>Lors de la publication des Annonce de Trajet, il est possible d'indiquer une ou des étapes, auxquelles le Conducteur accepte de s'arrêter pour prendre ou déposer des Passagers. Les segments du Trajet en Covoiturage entre ces villes étapes ou entre l'une de ces villes étapes et le point de départ ou d'arrivée du Trajet en Covoiturage constituent également des «Trajets ».</p>
              
              <p>Il n'est autorisé de publier une Annonce de Trajet sur la Plateforme ATCHOUM que si la politique de confidentialité ainsi que les présentes Conditions Générales d'Utilisation ont été expressément lues et approuvées.</p>
              
              <p>Responsabilité des Membres : les Membres sont responsables du contenu de leurs annonces, et s'engagent à garantir l'exactitude des informations publiées. Ils doivent réaliser les Trajets selon les modalités décrites dans les annonces.</p>
              
              <p>Visibilité des annonces : les annonces publiées sont visibles des Membres et des visiteurs utilisateurs. ATCHOUM se réserve le droit de refuser ou de supprimer toute annonce non conforme aux CGU ou qui pourrait nuire à son image.</p>
              
              <p><strong>Réservation d'une Place</strong></p>
              <p>Processus de réservation : les Membres peuvent réserver une Place via le Site internet ou le centre d'appels téléphoniques offert par l'Association. Une confirmation du Trajet est envoyée par email aux deux parties, incluant les détails du Trajet (lieu, contact, frais).</p>
              
              <p>Paiement : le paiement peut s'effectuer en ligne et par tickets mobilité ATCHOUM pour les réservations via le centre d'appels.</p>
              
              <p>Responsabilité contractuelle : une fois la réservation confirmée, les Passagers et Conducteurs sont seuls responsables de l'exécution du Trajet.</p>
              
              <p><strong>Caractère Nominatif des Réservations</strong></p>
              <p>Identité des Membres : toute utilisation des Fonctionnalités de la Plateforme est nominative ; les identités du Conducteur et des Passagers doivent correspondre aux informations communiquées.</p>
              
              <p>Réservations pour un tiers : les Membres peuvent réserver pour un tiers en fournissant avec exactitude les prénoms, âge et numéro de téléphone de la personne concernée.</p>
              
              <p>Restrictions d'usage : la réservation est uniquement destinée aux Trajets de personnes. Le transport de matériel, colis, ou animaux voyageurs seuls est strictement interdit.</p>
              
              <p>Les Conducteurs ne peuvent publier des annonces que pour des Trajets effectués avec leur propre véhicule, conforme aux exigences légales pour le Covoiturage. Les Conducteurs peuvent se détourner pour prendre ou déposer un Passager dans le cadre de Trajets solidaires ou d'utilit sociale, en lien avec l'association ATCHOUM.</p>
              
              <p>Conditions pour mineurs : la réservation pour un mineur tiers est interdite, sauf avec une autorisation écrite des parents ou représentants légaux.</p>
              
              <p>Limitation d'usage : la réservation est exclusivement destinée aux Trajets de personnes et exclut le transport de colis, objets ou animaux seuls.</p>
              
              <p>Conformité des véhicules : les Conducteurs doivent utiliser leur propre véhicule, conforme aux exigences légales pour le Covoiturage. Ils peuvent également participer à des Trajets solidaires ou d'utilité sociale organisés par l'Association.</p>
              
              <p><strong>Modération</strong></p>
              <p>ATCHOUM peut refuser ou supprimer tout contenu (questions, commentaires, réponses) jugé non conforme aux C/GU.</p>

              <p><strong>5. CONDITIONS FINANCIÈRES</strong></p>
              <p>L'accès et l'Inscription à la Plateforme ATCHOUM de même que la recherche, la consultation et la publication de Trajets sont gratuits. La fonctionnalité de mise en relation c'est-à-dire la conclusion d'une Réservation est gratuite également dans le cadre des convention d'abonnement des collectivités au centre d'appels qui ouvre le droit aux habitants du territoire concerné de bénéficier de toutes les Fonctionnalités de la plateforme ATCHOUM.</p>
              
              <p><strong>5.1. Participation aux Frais et Prix</strong></p>
              <p>⦁ Détermination de la Participation aux Frais</p>
              <p>Lorsqu'un Conducteur publie une Annonce de Trajet, la Plateforme ATCHOUM propose un montant indicatif de Participation aux Frais via son algorithme. Ce montant est calculé en fonction :</p>
              <p>⦁ de la distance parcourue.</p>
              <p>⦁ de la nature du Trajet (Covoiturage, Trajets solidaires ou Trajets d'utilité sociale).</p>
              <p>⦁ des frais réels supportés par le Conducteur.</p>
              
              <p>Le Conducteur peut ajuster ce montant à la hausse ou à la baisse dans les limites autorisées pour éviter tout abus et respecter la réglementation du Covoiturage. En acceptant une réservation, le Conducteur accepte le montant ajusté ou proposé. En Trajets Solidaires ou Trajets d'Utilité Sociale, le cas échéant, les frais de péages et de parking, sont toujours à la charge du Passager.</p>
              
              <p>⦁ Arrondis</p>
              <p>ATCHOUM peut arrondir le montant de la Participation aux Frais au centième d'euros supérieur, à sa discrétion.</p>
              
              <p><strong>5.3 Modalités de paiement et de reversement de la Participation aux Frais au Conducteur</strong></p>
              <p><strong>5.3.1 Mandat d'encaissement</strong></p>
              <p>Les Conducteurs confient à ATCHOUM un mandat d'encaissement pour percevoir la Participation aux Frais au nom et pour le compte du Conducteur, pour chaque Trajet. ATCHOUM encaisse la totalité des sommes versées par les Passagers (Participation aux Frais et frais de gestion) et les dépose sur un compte dédié. Les sommes perçues par ATCHOUM ne génèrent pas d'intérêts pour le Conducteur. Les Conducteurs doivent répondre aux demandes d'ATCHOUM ou des autorités administratives/judiciaires concernant des informations d'identité ou d'adresse pour prévenir la fraude et le blanchiment. En cas de non-réponse du Conducteur, ATCHOUM pourra prendre toute mesure qui lui semblera appropriée notamment le gel des sommes dues et la suspension de son compte.</p>
              
              <p><strong>5.3.2 Modalités de paiement</strong></p>
              <p>À la suite de la Confirmation de Trajet, ATCHOUM considère la confirmation du Trajet comme acquise. ATCHOUM encaisse alors le montant du Trajet tel que calculé et suggéré par l'algorithme de la plateforme ATCHOUM, ou modifié par le Conducteur dans les règles d'ajustements et accepté par le Passager.</p>
              
              <p>Les Passagers peuvent payer :</p>
              <p>⦁ Par carte bancaire.</p>
              <p>⦁ Par prélèvement SEPA.</p>
              <p>⦁ Par Tickets Mobilité ATCHOUM, disponibles dans les lieux définis par la Plateforme ou par la collectivité abonnée au centre d'appels téléphoniques, en fonction du lieu de résidence du Passager.</p>
              
              <p>Tout utilisateur utilisant ce mode de paiement est réputé accepter ces modalités. Chaque Ticket Mobilité ATCHOUM a une valeur de 1,25 €. Le montant dû est converti en un nombre entier de tickets, arrondi au supérieur. Exemple : Pour un montant de 7,03 €, le Passager devra fournir 6 tickets, correspondant à 7,50 €. Ce mode de calcul tient compte de l'impossibilité de disposer de Tickets Mobilité ATCHOUM correspondant exactement au montant de chaque Trajet possible et des frais de gestion spécifiques de ce mode de paiement. Les Tickets Mobilité ATCHOUM sont numérotés pour éviter la fraude, et leur utilisation implique l'acceptation des modalités correspondantes.</p>
              
              <p><strong>5.3.3 Versement de la Participation aux Frais au Conducteur</strong></p>
              <p>Après réalisation du Trajet, le Conducteur dispose d'un crédit exigible sur son compte ATCHOUM. Ce crédit correspond au montant payé par le Passager c'est-à-dire le montant de la Participation aux Frais payée par le Passager. Le virement des Participations aux Frais est effectué sous 7 jours maximum sur le compte bancaire renseigné par le Conducteur lors de son Inscription.</p>
              
              <p>Pour les Trajets payés par Tickets Mobilité ATCHOUM, le Conducteur doit transmettre les tickets à l'adresse indiquée au verso pour être crédité du montant correspondant. Le Conducteur accepte par défaut le paiement par Tickets Mobilité ATCHOUM, sauf refus explicite exprimé lors de son Inscription. Le Conducteur qui a encaissé ces Tickets Mobilité ATCHOUM pour le paiement de la participation aux frais de Trajet doit les transmettre à l'adresse indiquée au verso de chaque Tickets Mobilité ATCHOUM pour être crédité du montant de la Participation aux Frais de Trajets correspondante sur son compte bancaire dans le cadre des modalités décrites ci-dessus.</p>

              <p><strong>6. FINALITÉ NON COMMERCIALE ET NON PROFESSIONNELLE DE L'UTILISATION PAR DES CONDUCTEURS DES FONCTIONNALITÉS ET DE LA PLATEFORME ATCHOUM</strong></p>
              
              <p><strong>Engagement des Conducteurs</strong></p>
              <p>Les Conducteurs inscrits s'engagent à utiliser les Fonctionnalités de la Plateforme uniquement dans un cadre non professionnel et non commercial, pour partager des Trajets avec des Passagers. Chaque Conducteur s'engage à ne pas demander une Participation aux Frais supérieure aux frais réels supportés, en excluant sa propre part de Participation aux Frais. Les frais doivent notamment être calculés en se basant sur le barème fiscal kilométrique applicable et les Conducteurs doivent ajuster le montant suggéré par l'algorithme d'ATCHOUM si nécessaire. Les Conducteurs sont seuls responsables de l'exactitude du calcul des frais supportés pour chaque Trajet.</p>
              
              <p><strong>6.2 Limitation des Véhicules Utilisés</strong></p>
              <p>L'utilisation de véhicules professionnels (VTC, taxi), de voitures de fonction ou de service est strictement interdite. ATCHOUM peut par ailleurs demander à tout moment des documents attestant la conformité du véhicule, notamment :</p>
              <p>⦁ Une copie de la carte grise.</p>
              <p>⦁ Tout justificatif prouvant que le véhicule est autorisé à être utilisé sur la Plateforme.</p>
              
              <p>Chaque Conducteur s'engage ainsi à fournir à ATCHOUM, sur simple demande de sa part, une copie de sa carte grise et/ou tout autre document de nature à attester que son véhicule est conforme aux exigences du Covoiturage, qu'il est autorisé à utiliser ce véhicule sur la Plateforme ATCHOUM et qu'il n'en tirera aucun bénéfice.</p>
              
              <p><strong>Contrôle des Activités des Conducteurs</strong></p>
              <p>ATCHOUM surveillera les activités des Conducteurs pour s'assurer qu'elles restent conformes aux engagements non professionnels et non commerciaux. Les critères évalués incluent :</p>
              <p>⦁ La nature des Trajets proposés (longueur, type).</p>
              <p>⦁ La fréquence des Trajets effectués.</p>
              <p>⦁ Le nombre de Passagers transportés.</p>
              <p>⦁ Le montant de Participation aux Frais demandé.</p>
              
              <p>Si ATCHOUM suspecte une situation de bénéfice ou un usage non conforme, elle peut :</p>
              <p>⦁ Suspendre temporairement ou définitivement le compte du Conducteur.</p>
              <p>⦁ Limiter l'accès aux Fonctionnalités de la Plateforme.</p>

              <p><strong>7. POLITIQUE D'ANNULATION</strong></p>
              
              <p><strong>7.1. Modalités de remboursement en cas d'annulation</strong></p>
              <p>Conditions d'annulation après Confirmation de Réservation : l'annulation d'une Place d'un Trajet par le Conducteur ou le Passager après la Confirmation de Réservation du Conducteur sera soumise aux conditions suivantes :</p>
              
              <p>⦁ Annulation imputable au Passager :</p>
              <p>⦁ Si la réservation a été payée par carte bancaire, le montant sera remboursé.</p>
              <p>⦁ Si des tickets mobilité ont été utilisés, ils seront revalidés.</p>
              
              <p>⦁ Annulation imputable au Conducteur :</p>
              <p>⦁ Le Conducteur ne percevra aucun paiement.</p>
              <p>⦁ Si le Passager a payé par carte bancaire, il sera remboursé.</p>
              <p>⦁ Si des tickets mobilité ont été utilisés, ils seront également revalidés.</p>
              
              <p>Appréciation de la légitimité des remboursements : ATCHOUM se réserve le droit d'évaluer, à sa seule discrétion, la légitimité des demandes de remboursement en se basant sur les éléments fournis par les parties concernées.</p>
              
              <p><strong>Droit de Rétractation</strong></p>
              <p>En acceptant les CGU, chaque Membre reconnaît expressément que le contrat conclu en utilisant la Plateforme avec un autre Membre est exécuté immédiatement après la Confirmation de Trajet. Conformément à l'article L.221-28 du Code de la consommation, chaque Membre renonce explicitement à son droit de rétractation dès la Confirmation de Trajet.</p>

              <p><strong>8. COMPORTEMENT DES UTILISATEURS DE LA PLATEFORME ATCHOUM ET MEMBRES</strong></p>
              <p>Chaque Membre reconnait être seul responsable du respect 
                de l'ensemble des lois, règlements et obligations applicables
                 à son utilisation de la Plateforme ATCHOUM et à l'utiliser 
                 dans le cadre des Conditions Générales d'Utilisation (CGU) 
                 de la plateforme ATCHOUM qu'il a lues et acceptées.</p>

                 <p><strong> 9. SUSPENSION DE COMPTES, LIMITATION D’ACCÈS ET
                RÉSILIATION </strong> </p>
               <p> Chaque Membre a la possibilité de mettre fin à sa
                relation contractuelle avec ATCHOUM à tout moment, sans frais et
                sans motif. Pour cela, il lui suffit de se rendre dans l’onglet
                « Fermeture de compte » de sa page Profil de Membre. En cas de
                violation de la part d’un Membre des présentes CGU, notamment de
                ses obligations en tant que Membre mentionnées aux articles 6 et
                8 ci-dessus, ou en cas de dépassement du seuil visé à l’article
                5.1 ci-dessus ou si ATCHOUM a des raisons sérieuses de croire
                que la violation en cause est nécessaire pour protéger sa
                sécurité et son intégrité, celles de ses Membres ou de tiers ou
                à des fins de prévention des fraudes ou d’enquêtes, ATCHOUM se
                réserve la possibilité de :</p>
                ⦁ résilier, immédiatement et sans
                préavis, les présentes CGU. <br/>
                ⦁ et/ou empêcher la publication ou
                supprimer tout avis, annonce, messages, contenus, demande de
                Réservation, ou tout contenu publié par ce Membre sur la
                Plateforme ATCHOUM. <br/>
                ⦁ et/ou limiter l’accès et l’utilisation de
                la Plateforme ATCHOUM à ce Membre. <br/>
                ⦁ et/ou suspendre de façon
                temporaire ou permanente ce Membre. <br/>
                Lorsque cela est nécessaire,
                le Membre sera notifié de la mise en Place d’une telle mesure
                afin de lui permettre de donner des explications à ATCHOUM.
                ATCHOUM décidera, à sa seule discrétion, de lever ou non les
                mesures mises en Place. 
                <p> <strong>10. DONNÉES PERSONNELLES </strong></p>
               <p> Dans le cadre
                de l’utilisation de la Plateforme ATCHOUM, ATCHOUM collecte et
                traite certaines données personnelles des Membres. En
                s’inscrivant sur la Plateforme, chaque Membre consent
                explicitement au traitement de ses données personnelles,
                conformément aux dispositions légales et à la Politique de
                Confidentialité.</p>

                <p> <strong>Les finalités du traitement des données
                incluent :</strong></p> 
                ⦁ La gestion des Inscriptions et des comptes
                utilisateurs.<br/>
                 ⦁ La mise en relation entre Conducteurs et
                Passagers. <br/>
                ⦁ La sécurité et la prévention des fraudes.<br/> 
                ⦁L’amélioration des services et Fonctionnalités de la Plateforme.<br/>
                <p> <strong>Les Membres disposent des droits suivants :</strong></p> 
                 ⦁ Accès,
                rectification, suppression et opposition au traitement de leurs
                données.<br/>
                 ⦁ Portabilité de leurs données personnelles. <br/>
                 Ces droits
                peuvent être exercés en contactant ATCHOUM via l’adresse email
                suivante :  <a href="mailto:contact@atchoum.eu" className="cta">
            contact@atchoum.eu
          </a>. En acceptant les présentes CGU,
                chaque Membre déclare avoir pris connaissance de la Politique de
                Confidentialité accessible à tout moment via le Site internet
                d’ATCHOUM. Pour certains traitements, un consentement spécifique
                pourra être demandé conformément à la réglementation en vigueur.
                
                <p><strong>11. PROPRIÉTÉ INTELLECTUELLE </strong></p>
                <p><b>11.1 Contenu Publié par ATCHOUM</b></p>
                ATCHOUM est le seul propriétaire des droits de propriété
                intellectuelle relatifs à la Plateforme ATCHOUM, ses
                Fonctionnalités, son contenu (textes, images, dessins, logos,
                vidéos, sons, données, graphiques), ainsi qu’aux logiciels,
                algorithmes et bases de données qui en assurent le
                fonctionnement. <br/>
                Licence accordée aux Membres : ATCHOUM accorde à
                chaque Membre inscrit une licence non exclusive, personnelle et
                non cessible pour l’utilisation de la Plateforme et de ses
                Fonctionnalités, à des fins personnelles, privées et non
                commerciales, conformément aux finalités de la Plateforme.<br/>
                Restrictions d’utilisation : chaque Membre s’interdit toute
                utilisation ou exploitation non autorisée de la Plateforme, de
                ses Fonctionnalités et de leur contenu, y compris mais sans s’y
                limiter : <br/>
                ⦁ La reproduction, modification, adaptation,
                distribution, représentation publique ou diffusion de la
                Plateforme et de son contenu, sauf autorisation préalable écrite
                d’ATCHOUM.<br/>
                 ⦁ La décompilation ou l’ingénierie inverse des
                logiciels ou des Fonctionnalités de la Plateforme, sauf dans les
                cas expressément autorisés par la loi.<br/>
                 ⦁ L’extraction ou la
                tentative d’extraction de données substantielles de la
                Plateforme, notamment via des outils automatisés tels que robots
                d’aspiration de données. <br/>
                Sanctions en cas de non-conformité :<br/> 
                toute violation des droits de propriété intellectuelle d’ATCHOUM
                peut entraîner des poursuites judiciaires conformément au Code
                de la propriété intellectuelle et aux textes légaux applicables.
                <p><strong>11.2 Contenu Publié par les Membres Licence accordée à ATCHOUM :</strong></p>
                en publiant du contenu sur la Plateforme ATCHOUM (ci-après,
                "Contenu Membre"), chaque Membre concède à ATCHOUM une licence
                non exclusive et gratuite pour l’utilisation de ce contenu dans
                le cadre du fonctionnement et de la promotion des
                Fonctionnalités de la Plateforme. <br/>
                Étendue de la licence : la
                licence inclut les droits suivants, pour la France et le monde
                entier, pendant toute la durée de la relation contractuelle
                entre le Membre et ATCHOUM :<br/>
                 ⦁ Reproduction : ATCHOUM peut
                reproduire tout ou partie du Contenu Membre sur des supports
                numériques (serveurs, disques durs, cartes mémoire) pour des
                besoins de stockage, sauvegarde, transmission ou téléchargement.<br/>
                ⦁ Adaptation et traduction : ATCHOUM peut adapter et traduire le
                Contenu Membre pour fournir les Fonctionnalités, notamment en
                différentes langues. Les adaptations respecteront le droit moral
                du Membre.<br/>
                 ⦁ Modification de mise en forme : ATCHOUM peut
                modifier la mise en forme du Contenu Membre pour respecter la
                charte graphique de la Plateforme et assurer sa compatibilité
                technique avec les formats numériques utilisés. <br/>
                <b>Protection et
                respect des droits des Membres :</b> le Contenu Membre reste la
                propriété du Membre, et ATCHOUM s’engage à ne pas utiliser ce
                contenu à des fins commerciales sans consentement spécifique. À
                la fin de la relation contractuelle, le Membre peut demander la
                suppression de son Contenu Membre, sauf obligation légale de
                conservation pour ATCHOUM. <br/>
                <b>Transparence et responsabilité :</b>
                ATCHOUM garantit que le Contenu Membre ne sera utilisé que dans
                le cadre des Fonctionnalités de la Plateforme. Le Membre reste
                responsable de la légalité de son contenu et garantit qu’il ne
                porte atteinte ni aux droits de tiers ni à la réglementation en
                vigueur.
                <p><strong>12. RÔLE D’ATCHOUM</strong></p> 
                <p><b> 12.1 Mise en Relation via la Plateforme ATCHOUM</b></p> 
                ATCHOUM propose une Plateforme en ligne et un
                centre d’appels permettant aux Membres de publier et consulter
                des Annonces de Covoiturage. Ces Annonces permettent aux Membres
                de prendre connaissance des modalités des Trajets proposés et,
                le cas échéant, de réserver une Place directement auprès du
                Membre ayant publié l’annonce pour un Trajet ou un segment de
                celui-ci.<br/> ATCHOUM agit en tant qu’intermédiaire technique pour
                faciliter la mise en relation entre Conducteurs et Passagers,
                conformément aux présentes CGU. Chaque Membre reconnaît
                qu’ATCHOUM n’est partie à aucun contrat ou accord conclu entre
                Membres pour le partage des frais relatifs à un Trajet. La
                Plateforme ATCHOUM constitue une mise en relation en ligne et ne
                doit pas être confondue avec son centre d’appels téléphoniques.
                ⦁ Absence de Contrôle sur les Annonces et Trajets ATCHOUM ne
                possède ni ne gère les véhicules utilisés pour les Trajets
                proposés sur la Plateforme. <br/>
                ATCHOUM ne contrôle pas :<br/>
                 ⦁ La validité, la véracité ou la légalité des Annonces publiées. <br/>
                 ⦁ Les modalités des Trajets proposés ou réservés, qu’il s’agisse
                de Covoiturage ou courvoiturage. <br/>
                ⦁ Le comportement des Membres
                ou de leurs éventuels tiers de confiance avant, pendant ou après
                un Trajet. Les Membres agissent sous leur seule et entière
                responsabilité, y compris pour le respect des obligations
                légales (assurance, permis de conduire, conformité des
                véhicules, etc.). <br/>
                ⦁ Rôle limité d’intermédiaire Le rôle
                d’ATCHOUM se limite à fournir une interface technique pour
                publier, consulter et réserver des Annonces de Trajets (qu’il
                s’agisse de Covoiturage ou courvoiturage) et faciliter la
                communication et l’organisation des Trajets entrer les Membres.
                Pour le Courvoiturage (Trajets courts, domicile-travail ou
                intra-territoire), les Membres doivent également respecter les
                règles locales spécifiques liées à ces Trajets (partenariats
                avec des collectivités, utilisation de tickets mobilité, etc.).
                Les Conducteurs et Passagers sont responsables des engagements
                pris lors de la réservation d’un Trajet. <br/>ATCHOUM n’agit pas en
                tant que transporteur ou fournisseur de services de transport.
                Les Membres doivent respecter les dispositions du code civil,
                notamment les articles 1101 et suivants relatifs aux obligations
                contractuelles et à la responsabilité civile. <br/>
                ⦁ Limitation de
                Responsabilité d’ATCHOUM En sa qualité d’intermédiaire, ATCHOUM
                ne saurait être tenue responsable : <br/>
                ⦁ Des informations erronées
                ou trompeuses fournies dans les Annonces publiées par les
                Membres.<br/>
                 ⦁ Des annulations ou modifications des Trajets par les
                Membres. <br/>
                ⦁ Du comportement des Membres avant, pendant ou après
                un Trajet. Il est rappelé qu’ATCHOUM se réserve le droit de
                suspendre ou d’exclure tout Membre en cas de violation des
                présentes CGU ou d’abus dans l’utilisation de la Plateforme. 
                <p><strong>13. FONCTIONNEMENT, DISPONIBILITÉ ET FONCTIONNALITÉS DE LA
                PLATEFORME ATCHOUM</strong></p> 
                ATCHOUM s’efforcera, dans la mesure du
                possible, de maintenir la Plateforme ATCHOUM accessible 7 jours
                sur 7 et 24 heures sur 24. Néanmoins, l’accès à la Plateforme
                ATCHOUM pourra être temporairement suspendu, sans préavis, en
                raison d’opérations techniques de maintenance, de migration, de
                mises à jour ou en raison de pannes ou de contraintes liées au
                fonctionnement des réseaux. <br/>Par ailleurs ATCHOUM se réserve le
                droit de modifier ou d’interrompre, à sa seule discrétion, de
                manière temporaire ou permanente, tout ou partie de l’accès à la
                Plateforme ATCHOUM ou de ses Fonctionnalités. 
                <p><strong>14. Modification
                des CGU</strong></p> 
                Les présentes CGU et les documents intégrés par
                référence, notamment la Politique de Confidentialité, expriment
                l’intégralité de l’accord entre chaque Membre et ATCHOUM relatif
                à l’utilisation des Fonctionnalités de la Plateforme ATCHOUM.<br/>
                Tout autre document publié sur la Plateforme ATCHOUM, tel que la
                « Foire aux questions », « On parle de nous », ou « Actualités
                », n’a qu’une valeur informative.
                <br/>
                 Modification des CGU : ATCHOUM
                peut modifier les présentes CGU afin de s’adapter à l’évolution
                de l’environnement technologique et commercial ou de se
                conformer à la réglementation en vigueur. <br/>
                Toute modification des
                présentes CGU sera publiée sur la Plateforme ATCHOUM avec
                mention de la date de mise à jour et sera notifiée par ATCHOUM à
                tous les Membres inscrits au moins 30 jours avant leur entrée en
                vigueur. <br/>
                Droit des Membres : les Membres peuvent refuser les
                modifications des CGU. Dans ce cas, ils disposent de la
                possibilité de résilier leur compte avant la date d’entrée en
                vigueur des nouvelles CGU. Passé ce délai, l’utilisation de la
                Plateforme ATCHOUM vaut acceptation des modifications. Les
                présentes CGU sont accessibles à tout moment sur la Plateforme
                ATCHOUM et peuvent être consultées ou téléchargées par les
                Membres.  
                <p><strong>15. DROIT APPLICABLE – LITIGES</strong></p> Les présentes
                Conditions Générales d’Utilisation sont rédigées en français et
                soumises à la loi française. Réclamations auprès d’ATCHOUM : en
                cas de litige relatif à la Plateforme ATCHOUM ou à ses
                Fonctionnalités, chaque Membre est invité à adresser une
                réclamation écrite à l’adresse suivante :<br/>
                 ⦁ à l’adresse postale
                suivante : 36 Route de Toulon 83136 MEOUNES LES MONTRIEUX ou <br/>
                ⦁ par email à l’adresse ⦁  <a href="mailto:contact@atchoum.eu" className="cta">
            contact@atchoum.eu
          </a>. <br/> ATCHOUM s’engage à
                répondre dans un délai de 30 jours ouvrés à toute réclamation.<br/>

                Plateforme de résolution des litiges en ligne (RLL) : si aucune
                solution amiable n’est trouvée, les Membres peuvent soumettre
                leur litige via la plateforme de résolution des litiges en ligne
                mise en Place par la Commission Européenne suivante :
                ec.europa.eu/consumers/odr La Commission Européenne transmettra
                votre réclamation aux médiateurs nationaux compétents. <br/>
                Médiation
                de la consommation : conformément aux articles L.611-1 et
                suivants du code de la consommation, tout litige contractuel non
                résolu par le service utilisateurs d’ATCHOUM peut être soumis
                gratuitement à un médiateur de la consommation. Vous pouvez
                contacter l’Association Nationale des Médiateurs (ANM) : <br/>
                ⦁ Par
                courrier : 62, rue Tiquetonne, 75002 Paris. <br/>
                ⦁ Par email :
                formulaire de saisine en ligne sur ⦁ www.anm-conso.com. <br/>
                Le site
                www.economie.gouv.fr/mediation-conso fournit des informations
                complémentaires sur la médiation. 16. MENTIONS LÉGALES La
                Plateforme ATCHOUM est propulsée par la société ATCHOUM SAS,
                société anonyme simplifiée au capital de 10 000.00 euros
                immatriculée au Registre du Commerce et des Sociétés de
                Draguignan sous le numéro 831 044 763 00017 (numéro de TVA
                intracommunautaire : FR46 831044763) dont le siège social est
                situé 36 Route de Toulon 83136 MEOUNES LES MONTRIEUX (France)
                email : <b> <a href="mailto:contact@atchoum.eu" className="cta">
            contact@atchoum.eu
          </a></b>, tel <b>06 32 83 17 30</b>, représentée par
                son Président Vincent DESMAS également Directeur de la
                publication du Site.<br/>
                 La plateforme est opérée par
                l’« Association Atchoum, Mobilité Villages » aux mêmes
                coordonnées. <br/>
                Le Site est hébergé sur les serveurs de la société
                OVH. L’assurance de responsabilité civile professionnelle est
                souscrite auprès de MACIF Multi garanties activité sociale
                Contrat N° 16495093. La SAS Atchoum qui détient, maintien et
                développe la plateforme est assurée auprès de MAIF RC
                multirisques Contrat N° 4164057P. Pour toute question, vous
                pouvez contacter ATCHOUM en utilisant le formulaire de contact. 
                <p><strong>17. TRANSPARENCE DE LA PLATEFORME ATCHOUM </strong></p>
                Informations générales
                : conformément à l’article L.111-7 du code de la consommation,
                les informations relatives au référencement et au classement des
                Trajets sur la Plateforme ATCHOUM sont détaillées ci-dessous.
                Pour toute question, vous pouvez contacter ATCHOUM via l’adresse
                mail :  <a href="mailto:contact@atchoum.eu" className="cta">
            contact@atchoum.eu
          </a> . Critères de référencement : tout
                Conducteur, personne physique et Membre inscrit sur la
                Plateforme ATCHOUM, est autorisé à publier un Trajet sous
                réserve de respecter les présentes CGU. Les Trajets publiés
                respectant les CGU sont automatiquement référencés et affichés
                parmi les résultats de recherche. Critères de classement : les
                Trajets sont affichés de manière aléatoire en fonction de la
                demande des Passagers. <br/>
                L’algorithme tient compte uniquement des
                critères suivants :<br/> ⦁ Lieu de départ.<br/> ⦁ Lieu d’arrivée.<br/> ⦁
                Proximités géographiques pertinentes pour la demande du
                Passager.<br/> Il n’existe aucun système de promotion payante ou de
                traitement préférentiel. <br/>Indépendance et neutralité : ATCHOUM
                déclare qu’il n’existe aucun lien capitalistique, financier ou
                autre influence entre ATCHOUM et les Membres qui pourrait
                altérer le classement des Annonces. Les résultats sont générés
                de manière objective pour garantir une égalité de traitement
                entre tous les Conducteurs et Passagers. <br/>
                <strong className="mt-16">SAS ATCHOUM Monsieur</strong><br/>
                <strong><i>Vincent Desmas</i></strong>
                <br/>
                <img src={Signature} alt="signature" />

              </p>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Layout>
  );
};
export default Cgu;
