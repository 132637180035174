import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CryptoJS from "crypto-js";
// Import assets
import "../../assets/styles/login.scss";
import EyeOpened from "../../assets/images/open-eye.svg";
import EyeClosed from "../../assets/images/eyeClosed.svg";
import ReturnIcon from "../../assets/images/return.svg";
import Tears from "../../assets/images/tears.svg"
import Alert from "../../assets/images/danger.svg";
import Header from '../../components/header';
import Services from '../../services/global.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMyContext } from '../../context';
import { HelmetProvider ,Helmet} from 'react-helmet-async';
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [savedCredentials, setSavedCredentials] = useState({
    email: '',
    password: '',
  });

  const { setUserData } = useMyContext(); 
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = {
    email: savedCredentials.email,
    password: savedCredentials.password,
    rememberMe: false, // Default is unchecked
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Format de l\'email invalide')
      .required('L\'email est requis'),
    password: Yup.string()
      .min(8, 'Le mot de passe doit comporter au moins 8 caractères')
      .required('Le mot de passe est requis'),
    rememberMe: Yup.boolean().optional(),
  });

  const handleToggle = () => {
    setShowPassword(!showPassword);
  };

  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    // console.log('Form data:', values);

    Services.create(values, 'login')
      .then((res) => {
        // console.log(res);
        setUserData(res?.data);
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('userData', res.data.user.id);

        if (values.rememberMe) {
          const encryptedPassword = CryptoJS.AES.encrypt(values.password, process.env.REACT_APP_SECRET_KEY).toString();
          localStorage.setItem('email', values.email);
          localStorage.setItem('password', encryptedPassword);
        } 

        const redirectPath = location?.state?.redirectPath || '/';
        navigate(redirectPath, { replace: true, state: location?.state?.searchState || null });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          className: 'custom-toast-error',
        });
        console.error(err);
      });

    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const encryptedPassword = localStorage.getItem('password');

    const decryptedPassword = encryptedPassword
      ? CryptoJS.AES.decrypt(encryptedPassword, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
      : '';

    setSavedCredentials({
      email: savedEmail || '',
      password: decryptedPassword || '',
    });
  }, []);
  return (
    <>
    <HelmetProvider>
    <Helmet>
        <title>Page connexion </title>
        <meta name="description" content="page de connexion Atchoum" />
      </Helmet>
      </HelmetProvider>
    <div className="desktop">
       <Header/> 
    </div>
  
    <div className="bg-atchoum">
    <div className="container-fluid-lg">
      <h1 className="text-white p-16 d-flex">
      <button onClick={() => navigate(-1)} className='cta'>
        <img src={ReturnIcon} className="mr-8" alt="return icon" />
        </button>
        Connexion
      </h1>
    </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched ,isValid,dirty}) => (
          <Form className="mt-28 ">
            <div className="p-16">
               <div class="alert alert-info  col-lg-6 mx-auto" role="alert">
                <svg className="svg-inline--fa fa-bell fa-w-14 mr-8" aria-hidden="true" data-prefix="fa" data-icon="bell" width="20px" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M433.884 366.059C411.634 343.809 384 316.118 384 208c0-79.394-57.831-145.269-133.663-157.83A31.845 31.845 0 0 0 256 32c0-17.673-14.327-32-32-32s-32 14.327-32 32c0 6.75 2.095 13.008 5.663 18.17C121.831 62.731 64 128.606 64 208c0 108.118-27.643 135.809-49.893 158.059C-16.042 396.208 5.325 448 48.048 448H160c0 35.346 28.654 64 64 64s64-28.654 64-64h111.943c42.638 0 64.151-51.731 33.941-81.941zM224 472a8 8 0 0 1 0 16c-22.056 0-40-17.944-40-40h16c0 13.234 10.766 24 24 24z"></path></svg>
               <span>Si vous rencontrez des problèmes pour vous connecter à votre compte après la mise à jour du logiciel, Veuillez cliquez sur <a href="reset" className='cta'>ce lien</a> pour reseter votre password.<br/></span>
             </div>
            </div>
           
            <div className="p-16 position-relative  icon-container  col-lg-4 mx-auto">
            <img src={Tears} alt="Icon" className="floating-icon" /> 
              {/* Email Field */}
              <div className="floating-input-container">
                <Field
                  type="email"
                  name="email"
                  className={`floating-input radius-top ${touched.email && errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  placeholder=" " // important for floating effect
                />
                <label htmlFor="email" className="floating-label">
                  Adresse e-mail
                </label>
              
              </div>

              {/* Password Field */}
              <div className="floating-input-container position-relative">
                <Field
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  className={`floating-input radius-bottom ${touched.password && errors.password ? 'is-invalid' : ''}`}
                  id="password"
                  placeholder=" " // important for floating effect
                   autoComplete="current-password"
                />
                <label htmlFor="password" className="floating-label">
                  Mot de passe
                </label>
                <div
                  className="password-toggle-icon position-absolute"
                  style={{
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                  }}
                  onClick={handleToggle}
                >
                  {showPassword ? (
                    <img src={EyeOpened} alt="Hide Password" />
                  ) : (
                    <img src={EyeClosed} alt="Show Password" />
                  )}
                </div>
               
              </div>
              <div className="text-end mt-8">
            <a href="/reset" className="cta">Mot de passe oublié ?</a>  
            </div>
            <ErrorMessage name="email" >
                   {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
                    <ErrorMessage name="password" >
                   {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
                    <div className="form-group text-start mb-80 mt-8">
                  <label className="d-flex align-items-center current-text">
                    <Field
                      type="checkbox"
                      name="rememberMe"
                      id="rememberMe"
                    />
                    <span className="custom-checkbox"></span>
                    <span>
                     Se souvenir de moi
                    </span>
                  </label>

                 
                </div> 
            </div>
              
            {/* <ErrorMessage name="password" component="div" className="invalid-feedback" />
            <ErrorMessage name="email" component="div" className="invalid-feedback" /> */}
            
            <div className="bottom-white-block">
              <p className="current-text text-center-lg">
                Pas encore de compte ? <a href="/register" className="cta">Je m'inscris</a>
              </p>
              <button type="submit" 
                disabled={!(isValid && Object.keys(errors).length === 0)} 
              className="btn-primary-s mt-24 p-16 w-sm-100">Je me connecte</button>
            </div>
          </Form>
        )}
      </Formik>
     
      
    </div>
    </>
  );
};

export default Login;
