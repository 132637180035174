import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ReturnIcon from "../../assets/images/return.svg";
import Services from "../../services/global.service";
import { useEffect, useState } from "react";
import RaisonSignal from "../../components/modalRaison";
import Conducteur from "../../components/conducteur";
import CardTrajet from "../../components/trajetCard";
import Loader from "../../components/loader";
import { HelmetProvider, Helmet } from "react-helmet-async";

const LeTrajet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inputs_data = location?.state?.inputs_data;
  const params = useParams();
  const [trajet, setTrajet] = useState();
  const [reservation, setReservation] = useState();
  const [open, setOpen] = useState(false);
  const [raison, setRaison] = useState("");
  const [loading, setLoading] = useState(false); // Loader state

  const getTrajet = () => {
    setLoading(true); // Start loader
    Services.getOne(params.id, "trajet/getOne")
      .then((res) => {
        setTrajet(res.data);
        setReservation(
          res.data?.trajet_reservation.filter(
            (pa) => pa?.user_id == localStorage.getItem("userData")
          )
        );
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false); // Stop loader
      });
  };

  const handleDemand = () => {
    // console.log({trajet})
    if (trajet?.prixTrajet == 0 || trajet?.prixTrajet === null) {
      Services.create(
        {
          trajet_id: params.id,
          dateRservation: inputs_data?.dateDepart,
          nbrPlace: inputs_data?.nbrPlace || 1,
        },
        "addReservation"
      )
        .then((res) => {
          navigate(`/confirmation-demande`);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      navigate(`/paymentMethod/${trajet?.id}`, {
        state: { trajet, inputs_data },
      });
    }
  };

  useEffect(() => {
    getTrajet();
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Détails trajet </title>
          <meta name="description" content="détails trajet " />
        </Helmet>
      </HelmetProvider>

      {open && (
        <RaisonSignal
          open={open}
          setOpen={setOpen}
          raison={raison}
          setRaison={setRaison}
          user={trajet?.conducteur}
        />
      )}
      <div className="bg-grey">
        <div className="letrajet">
          <div className="col-lg-6 mx-auto le-trajet">
            <div className="container-fluid-lg p-16">
              <div className="">
                <h1 className="text-white d-flex">
                  <button onClick={() => navigate(-1)} className="cta">
                    <img src={ReturnIcon} className="mr-8" alt="return icon" />
                  </button>
                  Le trajet
                </h1>
              </div>
              {loading ? (
                // Display a loader while fetching data
                <Loader type="1" />
              ) : (
                <>
                  <div className="mt-24">
                    {trajet && (
                      <CardTrajet trajet={trajet} trajetType={"aller"} />
                    )}
                  </div>
                  {trajet?.dateRetour || trajet?.isFlexibleDest ? (
                    <div className="mt-24">
                      <CardTrajet trajet={trajet} trajetType={"retour"} />
                    </div>
                  ) : (
                    ""
                  )}
                  <h2 className="bleuM500 mt-24">Le prix</h2>
                  <div className="card-atchoum bg-white text-start ">
                    <span className="subtitle bleuM500 mr-8">
                      {parseFloat(trajet?.prixTrajet)?.toFixed(2) || 0}€
                    </span>
                    <span className="legend bleu400">
                      (Prix pour 1 passager)
                    </span>
                  </div>
                  {reservation && trajet && (
                    <Conducteur
                      conducteur={trajet?.conducteurtrajet}
                      reservation={reservation[0]}
                      open={open}
                      setOpen={setOpen}
                      title={true}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="h-xs-100"></div>
      <div className="bottom-white-block">
        <button
          className="btn-primary-s p-16 w-sm-100"
          onClick={() => {
            handleDemand();
          }}
          disabled={trajet?.demande || loading}
        >
          {trajet?.demande ? "Demande envoyée" : "Faire une demande de trajet"}
        </button>
      </div>
    </>
  );
};

export default LeTrajet;
