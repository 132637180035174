import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "./layout";
import { useNavigate } from "react-router-dom";
import ReturnIcon from "../assets/images/return.svg";

const Pc = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <HelmetProvider>
        <Helmet>
          <title>Politique de confidentialité </title>
          <meta
            name="description"
            content="page Politique de confidentialité"
          />
        </Helmet>
      </HelmetProvider>
      {/* <div className="bloc-search min-h-200">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex">
            <button onClick={() => navigate(-1)} className="cta">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </button>
            Politique de confidentialité
          </h1>
        </div>
        </div> */}
      <div className="p-16 col-lg-6 mx-auto">
        <h1 className="bleuM500 mt-24"> Politique de confidentialité</h1>
        <p className="exergue  mt-24">Introduction</p>
        <p className="current-text mt-8">
          La protection de votre vie privée est essentielle pour ATCHOUM. Cette
          politique de confidentialité décrit comment nous collectons,
          utilisons, partageons et protégeons vos données personnelles dans le
          cadre de nos activités de covoiturage et de partage de trajets. Elle
          détaille également vos droits et les moyens de les exercer. Pour toute
          question ou demande concernant vos données personnelles, veuillez
          contacter notre référent RGPD à l’adresse suivante :
          <a href="mailto:contact@atchoum.eu" className="cta">
            contact@atchoum.eu
          </a>
           
        </p>
        <p className="exergue  mt-24">
          1. Quels types de données personnelles ATCHOUM collecte-t-elle et pour
          quelles raisons ?
        </p>
        <p className="current-text mt-8">
          ATCHOUM collecte et utilise vos données personnelles pour gérer les
          activités de covoiturage et de partages de trajets. Il s’agit de
          traitements permettant la gestion de vos profils sur notre plateforme,
          la mise en relation conducteur/passager, la réservation de trajets, la
          gestion des données administratives nécessaires aux opérations de
          facturation et de statistiques.
          <br />
          À cette fin, nous collectons les types de données suivantes :
          <br />
          • Données d’identification : noms, prénoms, adresse postale, adresse
          électronique, numéro de téléphone, photo
          <br />
          • Données liées à la conduite : permis de conduire, carte grise,
          assurance auto,
          <br />
          • Données financières : RIB, informations relatives à la carte
          bancaire
          <br />
          • Données de connexion : adresse IP, journaux de connexion, cookies,
          <br />
          • données liées aux communications : messages échangés entre
          utilisateurs via la plateforme,
          <br />
          • préférences utilisateur : historique de navigation et comportement
          en ligne,
          <br />
          • données liées aux programmes d'assurances ou primes
          environnementales : informations nécessaires à la gestion des
          incitatifs ou des campagnes (date, localisation des trajets),
          <br />
          • données de géolocalisation : collectées lors de l'utilisation des
          services pour personnaliser les trajets et optimiser les services.
          <br />
          <p className="current-text mt-8">
            Vos données sont utilisées pour les finalités suivantes :
            <br />
            • gestion et optimisation de votre expérience sur la plateforme,
            <br />
            • mise en relation entre conducteurs et passagers,
            <br />
            • organisation et réservation des trajets,
            <br />
            • facturation et traitement des paiements,
            <br />
            • amélioration continue des services (ex. : analyse des données pour
            identifier des besoins utilisateurs),
            <br />
            • personnalisation des publicités et des contenus,
            <br />
            • communication entre utilisateurs de la plateforme,
            <br />
            • prévention et détection des fraudes,
            <br />
            • gestion des litiges et des incidents de sécurité,
            <br />
            • conformité aux obligations légales et réglementaires,
            <br />
            • participation à des programmes territoriaux ou d’entreprise pour
            la promotion de la mobilité durable,
            <br />
            • prédiction des trajets pour améliorer l’expérience utilisateur
            (avec consentement explicite).
            <br />
            La base légale du traitement repose sur :
            <br />
            • votre consentement (par exemple, pour l’utilisation des cookies).
            Vous nous l’accordez lorsque vous vous cochez la case consentement
            lors de votre inscription à notre plateforme. Votre consentement est
            facile à retirer, il vous suffit de supprimer votre profil.
            <br />
            • L’exécution d’un contrat (ex. : mise en relation entre
            utilisateurs).
            <br />
            • Le respect d’obligations légales (ex. : conservation des données
            de facturation).
            <br />• L’intérêt légitime (ex. : amélioration des services).
          </p>
        </p>
        <p className="exergue  mt-24">2. Les cookies</p>
        <p className="current-text mt-8">
          Nous respectons vos choix concernant l’utilisation de cookies. <br />
          Lorsque vous autorisez leur utilisation, les cookies nous permettent
          de vous proposer une navigation optimale et de mesurer l’audience du
          site.
          <br />
          Vous pouvez configurer vos préférences ou retirer votre consentement à
          tout moment via le panneau de gestion des cookies.
          <br />
          Par défaut, sans votre autorisation, aucun cookies d’identification
          personnelle n’est réalisée.
        </p>
        <p className="exergue  mt-24">
          3. Qui sont les destinataires de vos données et combien de temps les
          gardons-nous ?
        </p>
        <p className="current-text mt-8">
          Vos données peuvent être partagées avec :
          <br />
          • les services internes d’ATCHOUM,
          <br />
          • des prestataires tiers pour les services de paiement, d’assistance
          technique, d’hébergement ou de publicité. Ces prestataires ont une
          obligation contractuelle de respect des règles RGPD.
          <br />
          • Les autorités compétentes en cas d’obligation légale ou judiciaire.
          <br />
          • Les programmes environnementaux ou territoriaux : partage des
          données (nom, trajets, dates) avec les autorités responsables pour la
          gestion des primes ou incitatifs.
          <br />
          • Les partenaires commerciaux : uniquement après obtention de votre
          consentement explicite.
          <br />
          • Les fusions ou acquisitions : en cas de changement de contrôle
          d’ATCHOUM, vos données peuvent être transférées à des tiers, avec
          information préalable.
          <br />
          <strong>Transferts internationaux :</strong> dans le cadre de nos
          activités, vos données ne seront pas transférées en dehors de l’UE. Si
          cela venait à se produire, vos données pourraient être transférées aux
          États-Unis sous réserve des garanties suivantes : <br />
          adhésion aux décisions d’adéquation de la commission européenne.
          signature de clauses contractuelles types approuvées par la commission
          européenne.
        </p>
        <p className="exergue mt-24">4. Durée de conservation des données</p>
        <p className="current-text mt-8">
          Vos données sont conservées selon les durées suivantes :
          <br />
          • la durée de votre inscription + 1 an pour les données liées aux
          trajets,
          <br />
          • 5 ans pour les données de facturation,
          <br />
          • 13 mois pour les cookies,
          <br />
          • an pour les données sensibles telles que les pièces d’identité,
          <br />• 10 ans pour les données fiscales et comptables.
        </p>
        <p className="exergue mt-24">
          5. Comment ATCHOUM protège vos données ?
        </p>
        <p className="current-text mt-8">
          Les informations personnelles qui nous sont fournies par le biais de
          notre site web seront utilisées dans les objectifs décrits dans cette
          politique ou dans les pages du site pertinentes.
          <br />
          ATCHOUM est engagé dans une démarche de qualité en général et de
          sécurité en particulier. ATCHOUM utilise les services d’un cabinet
          certifié en protection de données (Bureau Véritas/CNIL et ISO 27001) ;
          cela nous permet de définir et mettre en œuvre les moyens
          organisationnels, techniques et juridiques adaptés contre les
          violations de confidentialité, d’intégrité et de disponibilité des
          données.
          <br />
          Notre système d’information est sécurisé et résilient. Nos partenaires
          sont maitrisés. Nos pratiques conformes à l’état de l’art. Notre
          personnel suit un programme de sensibilisation.
          <br />
          Nous mettons ainsi en œuvre des mesures organisationnelles, techniques
          et physiques pour protéger vos données contre les accès non autorisés,
          les altérations et les pertes.
          <br />
          En cas de violation de données personnelles susceptible de porter
          atteinte à vos droits et libertés, vous serez informé dans les
          meilleurs délais.
        </p>
        <p className="exergue mt-24">
          6. Quels sont vos droits et comment les exercer ?
        </p>
        <p className="current-text mt-8">
          <br />
          • Droit d’accès : vous pouvez nous demander la confirmation que des
          données à caractère personnel vous concernant sont ou non traitées par
          nos soins. Vous avez par ailleurs un droit d’accès aux données
          personnelles que nous détenons vous concernant. Vous pouvez formuler
          une demande de copie de ces informations, par écrit et accompagnée
          d’une copie d’un titre d’identité signé et à jour.
          <br />
          • Droit de rectification : vous disposez d’un droit de rectification
          de données inexactes ou incomplètes. Il vous suffit pour cela de nous
          faire parvenir par écrit une déclaration complémentaire.
          <br />
          • Droit à l’effacement : vous avez le droit de demander l’effacement
          de vos données à caractère personnel dans les cas suivants :
          <br />
          - les données ne sont plus nécessaires eu égard aux finalités pour
          lesquelles nous avons initialement effectué un traitement,
          <br />
          - vous avez retiré votre consentement pour le traitement de vos
          données, et il n’y a pas d’autre fondement juridique au traitement,
          <br />
          - les données ont fait l’objet d’un traitement illicite,
          <br />
          - vous vous êtes opposé au traitement nécessaire aux fins des intérêts
          légitimes poursuivis par ATCHOUM et il n’existe pas de motif légitime
          impérieux.
          <br />
          • Droit à la limitation du traitement : vous avez le droit d’obtenir
          la limitation du traitement de vos données. Vos données ne pourront
          dans ce cas faire l’objet d’aucun traitement par nos soins sans un
          consentement express de votre part, à l’exception de leur
          conservation. Vous avez la possibilité d’exercer ce droit dans les
          circonstances suivantes : vous contestez l’exactitude des données à
          caractère personnel que nous traitons vous concernant (le temps de
          nous permettre de procéder aux vérifications nécessaires), le
          traitement est illicite mais vous demandez à ce qu’il soit limité et
          non supprimé, vous vous opposez au traitement de vos données à
          caractère personnel aux fins des intérêts légitimes poursuivis par
          ATCHOUM, nous n’avons plus besoin de traiter vos données à caractère
          personnel mais ces données sont nécessaires à la constatation,
          l’exercice ou la défense d’un droit en justice.
          <br />
          • Droit à la portabilité des données : vous avez le droit de récupérer
          les données que vous avez fournies, dans un format structuré,
          couramment utilisé et lisible par une machine. Il doit s’agir de
          données traitées après recueil de votre consentement ou en raison de
          l’exécution d’un contrat vous liant à ATCHOUM.
          <br />
          • Droit d’opposition : vous pouvez vous opposer, pour des raisons
          tenant à votre situation particulière, à un traitement de données à
          caractère personnel vous concernant dès lors qu’il a pour base légale
          notre intérêt légitime.
          <br />
          • Droit de retirer votre consentement : lorsque ATCHOUM a obtenu votre
          consentement pour traiter vos données à caractère personnel pour une
          ou plusieurs finalités, vous pouvez retirer ce consentement à tout
          moment sauf s’il existe une autre base légale à ce traitement.
          <br />• Droit d’introduire une réclamation auprès de la CNIL : si vous
          estimez, après nous avoir contactés, que vos droits Informatique et
          Libertés ne sont pas respectés ou que les traitements et dispositifs
          ne sont pas conformes aux règles de protection des données, vous
          pouvez adresser une réclamation en ligne à la CNIL ou par courrier
          postal. Pour exercer vos droits contactez notre référent RGPD à
           contact@atchoum.eu
        </p>{" "}
        <p className="exergue  mt-24">
          7. Modification de la politique de confidentialité
        </p>
        <p className="current-text mt-8">
          ATCHOUM se réserve le droit de faire évoluer la présente politique de
          protection des données personnelles à tout moment, notamment en
          application des changements apportés aux lois et réglementations en
          vigueur. Les utilisateurs seront informés par email ou via la
          plateforme au moins 15 jours avant la mise en œuvre des modifications.
          Si vous n’acceptez pas les nouvelles conditions, vous pouvez supprimer
          votre compte.
        </p>
      </div>
      {/* </div> */}
    </Layout>
  );
};
export default Pc;
