import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import InputMask from "react-input-mask";
// Import assets
import "../../assets/styles/login.scss";
import Alert from "../../assets/images/danger.svg";
import Info from "../../assets/images/Information.svg";
import ReturnIcon from "../../assets/images/return.svg";
import { useNavigate } from "react-router-dom";
import ModalRetraite from "../../components/modalRetraite";
import Services from "../../services/global.service";
import InfoModal from "../../components/infoModal";
import { toast } from "react-toastify";
import { HelmetProvider ,Helmet} from "react-helmet-async";

const Register = () => {
  const navigate = useNavigate();
  const initialValues = {
    civilite: "",
    prenom: "",
    nom: "",
    dateNaissance: null, // Date is initialized as null
    numeroTel: "",
    adresse: "", // Added to store address
    conducteurSolidaire: false, // Default to unchecked
    adhereAssociation: false, // Default to unchecked
    accepteCguCgv: false, // Default to unchecked (must be true to submit)
    // Additional field for age > 64
    retraite: "", // Initialize additional field
  };

  const [adresse, setAdresse] = useState("");
  const [LatLng, setLatLng] = useState(null);
  const [adr, setAdr] = useState(null);
  const [error, setError] = useState("");
  const [modalRetraite, setModalRetraite] = useState(false);
  const [selectedRetraite, setSelectedRetraite] = useState("");
  const [autreCaisse, setAutreCaisse] = useState(selectedRetraite === "Autre" ? selectedRetraite : "");
  const [open, setOpen] = useState(false);
  const [openBulletin, setOpenBulletin] = useState(false);
  const [autorisation, setAutorisation] = useState(null);
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false);
  const textModalBulletin="Adhésion gratuite ,\n Si vous le souhaitez, vous pouvez bénéficier de l'assurance de l'association moyennant une cotisation de 5€/an (optionnel), vous serez couvert tout risque pour tous les trajets enregistrés et réalisés avec Atchoum Pour créer une communauté entre les utilisateurs et recevoir des invitations à des évènements ..."
  const textModal =
    "Vous souhaitez rendre service et vous proposez de répondre à des demandes de voyage, vous avez juste à cocher.";
  // Helper function to calculate age
  const calculateAge = (birthDate) => {
    // console.log({birthDate})
    if (!birthDate) return null;
    const today = new Date();
    const parts = birthDate.split('/'); // Assuming input is DD/MM/YYYY
    if (parts.length !== 3) return false;
    const formattedValue = `${parts[2]}-${parts[1]}-${parts[0]}`; // YYYY-MM-DD
    const birth = new Date(formattedValue);
    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    // console.log({age})
    return age;
  };

  const validationSchema = Yup.object({
    civilite: Yup.string().required("Civilité est requis"),

    prenom: Yup.string().required("Prénom est requis"),

    nom: Yup.string().required("Nom est requis"),

    adresse: Yup.string().required("Adresse est requis"),

    dateNaissance: Yup.string()
      .typeError("Veuillez entrer une date de naissance valide.")
      .required("La date de naissance est obligatoire.")
      .matches(
        /^\d{2}\/\d{2}\/\d{4}$/,
        "Veuillez entrer une date de naissance valide."
      ) // Ensure the format is DD/MM/YYYY
      .test(
        "valid-year",
        "Veuillez entrer une date de naissance valide.",
        (value) => {
          const year = value?.split("/")[2];
          return year?.startsWith("19") || year?.startsWith("20"); // Validate that it starts with 19 or 20
        }
      )
      .test(
        "validYear",
        "Veuillez entrer une date de naissance valide.",
        (value) => {
          // Preprocess the date input to convert to a valid format (DD/MM/YYYY → YYYY-MM-DD)
          const parts = value.split('/'); // Assuming input is DD/MM/YYYY
          if (parts.length !== 3) return false;

          const formattedValue = `${parts[2]}-${parts[1]}-${parts[0]}`; // YYYY-MM-DD
          const date = new Date(formattedValue);

          if (isNaN(date.getTime())) {
            // console.log('Invalid Date', value);
            return false;
          }
          const year = date.getFullYear();
          // console.log('value', date);

          // Check if the year was correctly parsed and if it’s a 4-digit year
          return year >= 1900 && year <= new Date().getFullYear(); // Ensure the year is in a reasonable range (1900 to current year)
        }
      )
      .test(
        "padYear",
        "L'année doit être comprise entre 1900 et l'année actuelle.",
        (value) => {
          const parts = value.split('/'); // Assuming input is DD/MM/YYYY
          if (parts.length !== 3) return false;

          const formattedValue = `${parts[2]}-${parts[1]}-${parts[0]}`; // YYYY-MM-DD
          const date = new Date(formattedValue);

          if (isNaN(date.getTime())) {
            // console.log('Invalid Date', value);
            return false;
          }
          const yearString = new Date(date).getFullYear().toString();
          // Handle cases like "0009" which JavaScript might interpret as "2009"
          if (yearString.length < 4) {
            return false; // Reject invalid short years
          }
          return true;
        }
      )
      .min(
        new Date(1900, 0, 1),
        "La date de naissance doit être après le 1er janvier 1900."
      )
      .max(new Date(), "La date de naissance ne peut pas être dans le futur.")
      .test(
        "age-range",
        "L'âge doit être compris entre 13 et 110 ans.",
        (value) => {
          const parts = value.split('/'); // Assuming input is DD/MM/YYYY
          if (parts.length !== 3) return false;

          const formattedValue = `${parts[2]}-${parts[1]}-${parts[0]}`; // YYYY-MM-DD
          const date = new Date(formattedValue);

          if (isNaN(date.getTime())) {
            // console.log('Invalid Date', value);
            return false;
          }
          const birthDate = new Date(date);
          const today = new Date();
          const age = today.getFullYear() - birthDate.getFullYear();
          const isWithinAgeRange = age >= 13 && age <= 110;
          return isWithinAgeRange; // Ensure age is between 13 and 100
        }
      ),

    numeroTel: Yup.string()
      .matches(
        /^(?:\+33|0033|0)[1-9]\d{8}$/, // No slashes needed in Yup's matches method
        "Numéro de téléphone invalide."
      )
      .required("Numéro de téléphone est requis"),

    conducteurSolidaire: Yup.boolean(),

    adhereAssociation: Yup.boolean(),

    accepteCguCgv: Yup.boolean().oneOf(
      [true],
      "Vous devez accepter les CGU et CGV"
    ),


  });
  const handleKeyDown = (e) => {
    const invalidKeys = [
      ...Array.from({ length: 10 }, (_, i) => `${i}`), // Numbers 0-9
    ];
  
    // Allow navigation keys (Backspace, Delete, Arrow keys)
    if (
      invalidKeys.includes(e.key) &&
      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)
    ) {
      e.preventDefault();
    }
  };
  const handleChangeAddress = (address, type, setFieldValue) => {
    if (type === "adresse") {
      setAdresse(address);
      setError("");
      setLatLng(null);
      setFieldValue("adresse", address);
      setError("");
    }
  };

  const handleSelect = async (address, type, setFieldValue) => {
    if (type === "adresse") {
      setAdresse(address);
      setFieldValue("adresse", address);
      setError("");
    }
    //code postal , ville
    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;

      const postalCode = addressComponents.find((component) =>
        component.types.includes("postal_code")
      );

      const street = addressComponents.find((component) =>
        component.types.includes("route")
      );
      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      );
      // console.log({ postalCode, street, city });

      // console.log("im hereee");
      const latLng = await getLatLng(results[0]);
      if (latLng && postalCode) {
        if (type === "adresse") {
          setLatLng(latLng);
          setAdr({ postalCode, street, city });
        }
      } else {
        console.error("Error: Unable to get latitude and longitude");
        toast.error('Adresse sans code postal !!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const searchOptions = {
    componentRestrictions: { country: ["fr"] },
  types: ["address"],
  };
  useEffect(() => {
    if (window.google) setIsGMapsLoaded(true);
    if (!window.google) {
      // Only load if Google Maps is not already loaded
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true); // Set state when loaded
      document.body.appendChild(gmapScriptEl);
    } else {
      setIsGMapsLoaded(true); // Google Maps is already loaded
    }
  }, []);
  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    // console.log("Form data:", adresse);
    if (!LatLng) {
      // console.log("here");
      setError("Adresse est obligatoire");
    }
    // Create a FormData instance
  const formData = new FormData();

  // Append form data fields
  formData.append("civilite", values.civilite === "pas" ? "" : values.civilite);
  formData.append("nom", values.nom);
  formData.append("prenom", values.prenom);
  formData.append("date_naissance", values.dateNaissance);
  formData.append("telephone", values.numeroTel);
  formData.append("adresse", adresse);
  formData.append("adresse_long", LatLng.lng);
  formData.append("adresse_lat", LatLng.lat);
  formData.append("postalCode", adr?.postalCode?.long_name || "");
  formData.append("street", adr?.street?.long_name || "");
  formData.append("city", adr?.city?.long_name || "");
  formData.append("caisse_retraite", selectedRetraite ==='Autre' ? autreCaisse:selectedRetraite);
  formData.append("adhesion_mobilite_village", values.adhereAssociation);
  formData.append("conducteur_solidaire", values.conducteurSolidaire);

  // Append file (ensure 'fileInput' is the reference to your file input)
  if (autorisation) {
    formData.append("autorisationParental", autorisation);
  }
    // console.log("token", localStorage.getItem("token"));
    Services.updatewithoutID(formData, "update_user")
      .then((res) => {
        // console.log("res", res.data);
        localStorage.setItem("userData", res?.data?.id);
        // console.log({values})
        if (values.conducteurSolidaire && !autorisation) {
          window.location.href = "/bulletin-adhesion";
        }
        else if(autorisation)
          {
            toast.success("Nous sommes en train de valider l'inscription de votre enfant. Un e-mail de validation sera envoyé dans les plus brefs délais.", {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              onClose:()=>navigate("/login"),
             // theme: 'light', // Choose theme (light/dark)
              className: 'custom-toast'
      
            });
            
          }
           
          else {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err?.response?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
      });
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // console.log({file})
    if (file) {
   
        setAutorisation(file); 
    } else {
      setAutorisation(null); // Réinitialise si aucune image n'est sélectionnée
    }
  };
  return (
    <>
    <HelmetProvider>
      <Helmet>
            <title>Page d'inscription </title>
            <meta name="description" content="stepe 3 inscription conducteur ou passager" />
        </Helmet>
    </HelmetProvider>
        
    <div className="bg-grey">
      {open && (
        <InfoModal
          open={open}
          setOpen={setOpen}
          title={"Qu’est ce qu’un conducteur solidaire ?"}
          text={textModal}
        />
      )}
      <InfoModal
          open={openBulletin}
          setOpen={setOpenBulletin}
          title={"Pourquoi adhérer à l'association ?"}
          text={textModalBulletin}
        />
      <div className="lastStepBg ">
        <div className="container-fluid-lg p-16">
          <h1 className="text-white d-flex mt-8">
            <button onClick={() => navigate(-1)} className="cta">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </button>
            Inscription
          </h1>
        </div>
      </div>
      <h2 className="col-lg-4 mx-auto px-16">Vos informations personnelles</h2>
      {modalRetraite && (
        <ModalRetraite
          open={modalRetraite}
          setOpen={setModalRetraite}
          selectedRetraite={selectedRetraite}
          setSelectedRetraite={setSelectedRetraite}
          autreCaisse={autreCaisse}
          setAutreCaisse={setAutreCaisse}
        />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, isValid, dirty, setFieldValue }) => {
          const age = calculateAge(values.dateNaissance);
          return (
            <Form className="mt-8 ">
              <div className="p-16 col-lg-4 mx-auto">
                {/* Civilité Field as Radio Buttons */}
                <div className="form-group text-start">
                  <label className=" exergue bleu600">Civilité</label>
                  <div
                    role="group"
                    aria-labelledby="civilite-radio-group mt-16"
                    className="mt-16"
                  >
                    <div>
                      <label className="d-flex align-items-center current-text bleuM500">
                        <Field
                          type="radio"
                          name="civilite"
                          value="Mme."
                          id="femme"
                        />
                        <span className="custom-radio"></span> Femme
                      </label>
                    </div>
                    <div className="mt-8">
                      <label className="d-flex align-items-center current-text bleuM500">
                        <Field
                          type="radio"
                          name="civilite"
                          value="M."
                          id="homme"
                        />
                        <span className="custom-radio"></span> Homme
                      </label>
                    </div>
                    <div className="mt-8">
                      <label className="d-flex align-items-center current-text bleuM500">
                        <Field
                          type="radio"
                          name="civilite"
                          value="pas"
                          id="neSePrononcePas"
                        />
                        <span className="custom-radio"></span> Ne se prononce
                        pas
                      </label>
                    </div>
                  </div>
                  {touched.civilite && errors.civilite ? (
                    <div className="invalid-feedback">{errors.civilite}</div>
                  ) : null}
                </div>

                {/* Prénom Field */}
                <div className="floating-input-container mt-16">
                  <Field
                    type="text"
                    name="prenom"
                    onKeyDown={handleKeyDown}
                    className={`floating-input radius-top ${
                      touched.prenom && errors.prenom ? "is-invalid" : ""
                    }`}
                    id="prenom"
                    placeholder=""
                  />
                  <label htmlFor="prenom" className="floating-label">
                    Prénom
                  </label>
                </div>

                {/* Nom Field */}
                <div className="floating-input-container">
                  <Field
                    type="text"
                    name="nom"
                    onKeyDown={handleKeyDown}
                    className={`floating-input radius-bottom ${
                      touched.nom && errors.nom ? "is-invalid" : ""
                    }`}
                    id="nom"
                    placeholder=""
                  />
                  <label htmlFor="nom" className="floating-label">
                    Nom
                  </label>
                </div>
                <ErrorMessage name="prenom">
                  {(msg) => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
                {/* Display error message below input */}
                <ErrorMessage name="nom">
                  {(msg) => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
                {/* Date de naissance Field */}
                <div className="floating-input-container mt-8">
                  <Field name="dateNaissance">
                    {({ field }) => (
                      <InputMask
                        {...field}
                        mask="99/99/9999"
                        placeholder=""
                        className={`floating-input radius-24 ${
                          touched.dateNaissance && errors.dateNaissance
                            ? "is-invalid"
                            : ""
                        }`}
                        id="dateNaissance"
                      />
                    )}
                  </Field>
                  <label htmlFor="dateNaissance" className="floating-label">
                    Date de naissance (JJ/MM/AAAA)
                  </label>
                  {/* Display error message below input */}
                  <ErrorMessage name="dateNaissance">
                    {(msg) => (
                      <div className="error-message">
                        <img src={Alert} alt="Alert" />
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
  {/* Conditional Additional Input for Age <13  */}
               {(age !== null && age >= 13 && age <18) && (
                  <div className="floating-input-container mt-16">
                    <div
                      className="btn-secondary-s p-14 w-100"
                      onClick={() =>
                        document.getElementById("autorisation").click()
                      }
                    >
                      {/* {console.log({autorisation})} */}
                  {autorisation?.name || 'Ajouter une autorisation parentale'}
                </div>
                <input
                  type="file"
                  id="autorisation"
                 // accept="image/*"
                 // multiple
                 accept="image/*,application/pdf"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                    <ErrorMessage name="autorisation">
                      {(msg) => (
                        <div className="error-message">
                          <img src={Alert} alt="Alert" />
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                )}
                {/* Numéro de téléphone Field */}
                <div className="floating-input-container mt-8">
                  <Field
                    type="tel"
                    name="numeroTel"
                    className={`floating-input ${
                      touched.numeroTel && errors.numeroTel ? "is-invalid" : ""
                    }`}
                    id="numeroTel"
                    placeholder=""
                  />
                  <label htmlFor="numeroTel" className="floating-label">
                    Numéro de téléphone
                  </label>
                  {/* Display error message below input */}
                  <ErrorMessage name="numeroTel">
                    {(msg) => (
                      <div className="error-message">
                        <img src={Alert} alt="Alert" />
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>

                {/* Adresse Field with Autocomplete */}
                <div className="floating-input-container mt-8">
                  {isGMapsLoaded && (
                    <Autocomplete
                      value={adresse}
                      searchOptions={searchOptions}
                      onChange={(address) =>
                        handleChangeAddress(address, "adresse", setFieldValue)
                      }
                      onSelect={(address) =>
                        handleSelect(address, "adresse", setFieldValue)
                      }
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="floating-input-container ">
                          <input
                            {...getInputProps({
                              placeholder: "",

                              className: ` floating-input  ${
                                error ? "danger-input" : ""
                              }`,
                            })}
                            className={`floating-input ${
                              touched.adresse && errors.adresse
                                ? "is-invalid"
                                : ""
                            }`}
                            id="floatingAdr"
                            name="adresse"
                          />
                          <label
                            htmlFor="floatingAdr"
                            className="floating-label"
                          >
                            Adresse
                          </label>
                          <div
                            className={
                              suggestions.length > 0
                                ? "container-autocomplete"
                                : ""
                            }
                          >
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41B6E6"
                                  : "#fff",
                              };
                              return (
                                <div
                                  className="mapItem"
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Autocomplete>
                  )}
                  <ErrorMessage name="adresse">
                    {(msg) => (
                      <div className="error-message">
                        <img src={Alert} alt="Alert" />
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                  {/* <div className="error-message">{error}</div> */}
                </div>

                {/* Conditional Additional Input for Age > 64 */}
                {age !== null && age > 60 && (
                  <div className="floating-input-container mt-16">
                    <Field
                      type="button"
                      value={`${
                        selectedRetraite === ""
                          ? "Renseigner votre caisse de retraite"
                          : selectedRetraite
                      }`}
                      className=" pt-16 floating-input text-start bg-white deroulante"
                      onClick={() => setModalRetraite(true)} // Open modal on click
                    />
                    <ErrorMessage name="retraite">
                      {(msg) => (
                        <div className="error-message">
                          <img src={Alert} alt="Alert" />
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                )}
               

                {/* Checkbox: Conducteur Solidaire */}
                <div className="form-group text-start mt-16 d-flex align-items-center">
                  <label className="d-flex align-items-center current-text">
                    <Field
                      type="checkbox"
                      name="conducteurSolidaire"
                      id="conducteurSolidaire"
                      disabled={age<18}
                    />
                    <span className="custom-checkbox"></span> Je souhaite être
                    conducteur solidaire
                  </label>
                  <button
                    type="button" // Prevents the form from submitting
                    className="cta"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    <img src={Info} alt="info icon " />
                  </button>
                </div>

                {/* Checkbox: Adhère Association */}
                <div className="form-group text-start mt-8">
                  <label className="d-flex align-items-center current-text">
                    <Field
                      type="checkbox"
                      name="adhereAssociation"
                      id="adhereAssociation"
                    />
                    <span className="custom-checkbox"></span>
                    <span>
                      Je souhaite adhérer
                      <a  href="https://admin.atchoum.eu/Bulletin_adh%C3%A9sion_Atchoum-_004_-V-GILBERT-_1_.pdf" target="_blank" className="cta-bleu current-text">
                        {" "}
                        à l'association atchoum mobilité villages{" "}
                      </a><button
                    type="button" // Prevents the form from submitting
                    className="cta"
                    onClick={() => {
                      setOpenBulletin(!openBulletin);
                    }}
                  >
                    <img src={Info} alt="info icon " />
                  </button>
                    </span>
                  </label>
                  
                </div>

                {/* Checkbox: Accepte CGU CGV (this must be true) */}
                <div className="form-group text-start mb-80 mt-8">
                  <label className="d-flex align-items-center current-text">
                    <Field
                      type="checkbox"
                      name="accepteCguCgv"
                      id="accepteCguCgv"
                    />
                    <span className="custom-checkbox"></span>
                    <span>
                      {" "}
                      J’accepte les
                      <a href="/cgu" target="_blank" className="cta-bleu current-text">
                        {" "}
                        CGU
                      </a>{" "}
                      et 
                      <a href="/pc" target="_blank" className="cta-bleu current-text">
                        {" "}
                        politique de confidentialité
                      </a>
                    </span>
                  </label>

                  {touched.accepteCguCgv && errors.accepteCguCgv ? (
                    <div className="error-message">{errors.accepteCguCgv}</div>
                  ) : null}
                </div>
              </div>
              {/* Submit Button */}
              <div className="bottom-white-block">
                <button
                  type="submit"
                  disabled={!(isValid && dirty)}
                  className="btn-primary-s p-16 w-sm-100"
                >
                  Terminer
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
    </>
  );
};

export default Register;
