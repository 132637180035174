


import Layout from "./layout";
import { HelmetProvider ,Helmet} from "react-helmet-async";
import NotFound from '../assets/images/notfound.svg'
const NotFoundRoute = () => {
  return (
    <Layout>
      <HelmetProvider>
       <Helmet>
            <title>Page 404 </title>
            <meta name="description" content="page ou path non trouvé  " />
        </Helmet>
        </HelmetProvider>
      <div className="bg-grey min-h-50 ">
     
        <div className="not-found-img pb-80 container d-flex align-items-center justify-content-center">
          <div className="row align-items-center">
            <div className="col-md-6 mx-auto  mt-32">
           
              <h2 className="grey500  bold500 text-center">Oh oh, la page que vous cherchez n’existe pas ...</h2>

              <div className="mt-42 pb-16 text-center">
                <a className="cta" href="/">Retour à la page d’accueil</a>
              </div> 
            </div>
            <div className="col-md-6 mt-24 text-center">
            <img src={NotFound} alt="not found " className="w-75" />
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
};

export default NotFoundRoute;
